import { COLORS } from "components/STYLE_CONFIG";
import { default as React, useState } from "react";
import { Header1, Header2, Header3, Paragraph } from "components/Typography";
import styled from "styled-components";
import flowchartimgUrl from "assets/research_course_flowchart.jpeg";
import { CommonContentBlock } from "components/ContentBlock";
import { Link } from "react-router-dom";
import WorkshopSlidesUrl from "assets/workshop_slides.pdf";

const Image = styled.img`
    width: 90%;
    margin: 15px;
`;

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const PureStudent: React.FC = () => {
    return (
        <PageWrapper>
            <Header1 color={COLORS.DARK_RED}>PURE Student guide</Header1>
            <div style={{ marginTop: "30px", marginBottom: "40px" }}>
                <Paragraph>
                    <div>
                        We are excited to announce the 2024-25 <b>Paid Undergrad Research Experience (PURE)</b>, a program designed to financially support undergraduate students who are interested in getting involved in CS research during the academic year! This program was first launched in Winter 2022 and aims to support first-generation and/or low income (FLI) students by providing compensation for their work as an undergraduate research assistant in a Stanford CS research lab. We especially hope to support students who have little or no prior research experience to help them explore CS research. Acceptance to the program is on a quarterly basis.
                    </div>
                    <br />
                    <div>
                        PURE positions are funded by Federal Work Study (FWS). This is a program by the U.S. goverment for U.S. citizens and U.S. permanent residents. It is available to <a href="https://studentaid.gov/understand-aid/eligibility/requirements/non-us-citizens">certain groups</a> of non-citizens. Federal Work Study is under a larger umbrella of Federal Student Aid. Generally speaking, if you have been given FWS funds to support your education, you should already know: you've filled out a FAFSA (Free Application for Federal Student Aid), been granted Federal student aid, and received a letter from Stanford telling you the amount of money you can earn with FWS. If you have funds through a different but similar program (e.g., you are from a country other than the U.S. and your country has a similar program), please reach out to the CURIS staff. We will look into whether you can use it for PURE. Alternatively, individual faculty members can fund undergraduate research positions through PURE using their own lab funds. 
                    </div>
                    <br />
                    <div>
                        Please check that either you have FWS funds or have secured funding from a faculty member before applying for PURE. We don't want you to go through a great deal of effort only to discover that you don't have funding. Many students on Stanford financial aid do not have FWS funds: the two are not the same, so please verify your FWS status.
                    </div>
                </Paragraph>
            </div>

            <Header2>Key Details</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                    <b>Fall 2024 Applications:</b><br />
                    <ul>
                        <li>
                            <b>Deadline:</b> 9/27/24, 11:59 PT
                        </li>
                        <li>
                            <b><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSc_uTbDRyIEbz0_UWONMSqQdG5w4D4NYVLBJ9Sqrl2jqYDOcg/viewform?usp=sf_link" }} target="_blank">Fall application form</Link></b>
                        </li>
                    </ul>


                    <b>Winter 2025 Applications:</b><br />
                    <ul>
                        <li>
                            <b>Deadline:</b> 1/10/25, 11:59 PT
                        </li>
                        <li>
                            <b><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSelzeSF3RZ8bxv0MRfs5g70Ei59u2eQ1T0cgCEVje8RGf7DqA/viewform?usp=sf_link" }} target="_blank">Winter application form</Link></b>
                        </li>
                    </ul>

                    <b>Spring 2025 Applications:</b><br />
                    <ul>
                        <li>
                            <b>Deadline:</b> 4/4/25, 11:59 PT
                        </li>
                        <li>
                            <b><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSfsu9Glhb17IqWkgXbEnH47j5HOoXUrNOe7OyT3NlNnldoOrA/viewform?usp=sf_link" }} target="_blank">Spring application form</Link></b>
                        </li>
                    </ul>

                    <b> Helpful resources:</b> <br />
                    <ul>
                        <li> 
                            <b><Link to="/year_round_cs_projects/">Year-round CS Projects</Link></b>: Browse the academic-year project listings (please filter to the appropriate quarter) as a starting point for potential PURE research advisors if you are not sure who you might want to work with.
                        </li>
                        <li> 
                            <b><Link to="/pure_advisor/">PURE Research Advisor Guide</Link></b>: information about PURE for research advisors
                        </li>
			<li>
			    <b>Email Template</b>: The following is an email opening you could send to a faculty member or PhD student for PURE:  
			    <ul>
				<li>I'm [Name] and I'm [brief intro to your major, year, academic interests]. I'm interested in being a research assistant next quarter for your project, [project title], through PURE (the Paid Undergraduate Research Experience program). In short, this program provides paid academic-year CS research experiences funded through Federal Work-Study, so no funding needs to come from your research lab. 
				</li>
			    </ul>			
			</li>
			<li>
                            <b>Funded CS Research Opportunities Workshop</b>: The links and deadlines in this workshop are for Winter 2022 PURE, but the application tips can still be helpful. Here are the <Link to={{ pathname: WorkshopSlidesUrl}} target="_blank">slides</Link> and <Link to={{ pathname: "https://stanford.zoom.us/rec/share/oL2xPsdKC8efZzQuYpDLS1_CZn201igmKpM48Kbu6Tc9xm11hG1uZVM_YPd7k8KE.mcLOHT0DgBvZda40" }} target="_blank">recording</Link> for the workshop.
                        </li>
                    </ul>
                </Paragraph>
            </div>

            <Header2>FAQs</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                <ul>
                    <li> 
                        <i>Where does the student compensation come from? Does it need to come from the faculty member/lab?</i>
                        <ul>
                            <li>The compensation comes from Stanford through the Federal Work-Study program. Similar to other FWS roles that are on-campus jobs paid for by the university, this program offers a FWS role in the form of a research assistantship with a CS research group! This means that <i>no</i> funding needs to come from the individual faculty member/lab.</li>
                        </ul>
                    </li>
                    <li> 
                        <i>How will students be selected for PURE? Are there any limits to the number of students who can take part in the program?</i>
                        <ul>
                            <li>Any student who is eligible for FWS and who has confirmed their alignment with a research advisor can take part in PURE. Since funding comes directly through the FWS program, we have no limits on the number of students who can take part in the program. The research proposal is meant to confirm that the research advisor(s) and the student have agreed to work together and are aligned on a research project of sufficient scope for the next quarter.</li>
                        </ul>
                    </li>
                    <li> 
                        <i>I’m not eligible for FWS. Can I still apply for PURE?</i>
                        <ul>
                            <li>If you’re not eligible for FWS, you can still apply to PURE if you’ve talked with your research advisor and have received confirmation from them that they can provide their own funding for you. In that case, you can indicate this on the application form, and we can assist with the paperwork.</li>
                        </ul>
                    </li>
                    <li> 
                        <i>I’m already working with a research group and I qualify for FWS; can I apply?</i>
                        <ul>
                            <li> Yes, if you are already working with a research group and qualify for FWS, we encourage you to apply to the program! The program is not restricted to students who aren't already connected with a lab.</li>
                        </ul>
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Eligibility</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                In order to be eligible for this opportunity, students must be:
                <ul>
                    <li> a currently-enrolled undergraduate student at Stanford </li>
                    <li> eligible for Federal Work-Study </li>
                    <li> planning to conduct research with a CS faculty member </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Program Benefits</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                Students will receive compensation through Federal Work-Study as hired research assistants for the duration of the quarter for which they applied to the program. Student pay is determined by Stanford <Link to={{ pathname: "https://financialaid.stanford.edu/faculty_staff/undergrad/wage_scale.html" }} target="_blank"> pay scales </Link> and is set at Level 3 (<Link to={{ pathname: "https://financialaid.stanford.edu/faculty_staff/undergrad/wage_scale.html" }} target="_blank">defined here</Link>). We will work with the Stanford Financial Aid Office to coordinate your payment. Students can participate in this program for one quarter at a time and can apply to extend their RA position to additional quarters if they are interested.
                </Paragraph>
            </div>

            <Header2>Program Expectations</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                We expect that students will commit the equivalent of 3 units (~8-10 hours per week) of work to their research assistantship. We also expect students to give a final, end-of-quarter presentation to their lab group; this presents a great opportunity to publicize your research more widely and build experience with research communication!
                </Paragraph>
            </div>

            <Header2>Application Materials</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                <ul>
                    <li><b>500-word Research Proposal:</b> write a proposal that describes the research project that you would like to work on during your research experience. We recommend that you develop this proposal with the research group / research mentor(s) that you intend to work with so that the proposal will be concrete and most directly useful to your RA-ship experience.
                    </li>
                    <li><b>The name and contact details of your research advisors:</b> (both the faculty and PhD/Post-doc research advisors) and confirmation that they have reviewed the URF program infosheet and have agreed to the <Link to="/pure_advisor/">Research Advisor Program Expectations</Link>.
                    </li>
                </ul>
                </Paragraph>
            </div>

            <Header2>Program Goals & Background</Header2>
            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                <Paragraph>
                We recognize that the current mode of operation for academic-year research presents significant challenges for FLI students. Stanford undergraduates typically either (1) enroll in research for units via courses like CS 195 or CS 199, which essentially means that students need to pay (via tuition) to conduct research or (2) participate in research work informally on top of their course load, jobs, and other extracurricular commitments, which presents a high barrier to entry that many students cannot cross. We aim to make research more accessible to FLI students and to set them up for success by enabling them to be compensated for their research work. The current iteration of the program is limited to students who are eligible for Federal Work-Study, but we hope to expand this program in the future, as we recognize that this program does not capture all individuals who are in financial need (for example, international students who are not eligible for FWS).
                </Paragraph>
            </div>

        </PageWrapper>
    )
}
